<template>
  <b-card-header :class="backgroundClasses">
    <div class="content">
      <div class="d-flex align-items-center">
        <div :class="emailbackgroundClasses">
          <b><span v-if="lead.userId || tokenCan('leads:id') || $route.query.search">#{{ lead.id }}</span></b>
        </div>
        <div class="pl-3">Last updated: {{ lead.updatedAt | moment('from', 'now') }}</div>
        <div class="ml-4" v-if="showCalling">
          <i class="fa fa-phone"></i>
        </div>
      </div>

      <div>
        <div v-if="isClaimed" class="h5 mb-0 d-inline-flex align-items-center">
          <avatar class="mr-2" :photo="lead.userPhoto" :size="32" />
          Claimed by <b class="mx-1">{{ lead.user ? lead.user.name.toUpperCase() : '' }}</b> <i v-if="icon" :class="icon"></i>
        </div>
        <div v-else class="h5 mb-0 d-inline-flex align-items-center">Unclaimed <i v-if="icon" :class="icon"></i></div>
      </div>

      <div class="d-flex align-items-center">
        <b-btn v-if="tokenCan('admin')" :variant="lead.createdAt == lead.sortOrder ? 'primary' : 'secondary'" @click="handleReflow" class="mr-1">{{ lead.createdAt == lead.sortOrder ? 'Reflow' : 'Reflowed' }}</b-btn>
        <b-btn v-if="canBeClaimed" variant="primary" @click="handleClaim" class="mr-1">Claim</b-btn>
        <b-btn v-if="canBeReclaimed" :disabled="isReclaimRequested" variant="secondary" @click="clickedReclaim = true" class="mr-1">
          <span v-if="isReclaimRequested">Reclaim requested</span>
          <span v-else>Reclaim</span>
        </b-btn>
        <div class="ml-3 text-center priorities">
          <div>
            <b-button v-b-tooltip.hover title="No Priority" class="nopriority btn-prior" @click="handlePriorityChange(0)"></b-button>
            <b-button v-b-tooltip.hover title="Hot" class="hot btn-prior" @click="handlePriorityChange(1)"></b-button>
            <b-button v-b-tooltip.hover title="Future" class="future btn-prior" @click="handlePriorityChange(2)"></b-button>
            <b-button v-b-tooltip.hover title="Booked" class="booked btn-prior" @click="handlePriorityChange(3)"></b-button>
            <b-button v-b-tooltip.hover title="Bad" class="bad btn-prior" @click="handlePriorityChange(4)"></b-button>
          </div>
        </div>
      </div>
      <ConfirmationModal v-if="clickedReclaim" @confirm="handleReclaim" @cancel="clickedReclaim = false" label="Are you sure you want to reclaim this lead?" />
    </div>
  </b-card-header>
</template>

<script>
import { mapState } from 'vuex';
import prioritiesMixin from '@/mixins/priorities';
import Avatar from '@/components/shared/avatar/Avatar';
import ConfirmationModal from '@/components/shared/ConfirmationModal';
import permissionsMixin from '/src/mixins/permissions';
import moment from 'moment';

export default {
  name: 'LeadsCardHeader',
  components: {
    Avatar,
    ConfirmationModal,
  },
  mixins: [prioritiesMixin,permissionsMixin],
  data() {
    return {
      multivalue: '',
      reclaimSent: false,
      clickedReclaim: false,
      clikedRed: false,
      callTimeOut: false,
    };
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: Object,
      default: () => ({
        priority: null,
      }),
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    showCalling() {
      if (this.lead.callAt && this.lead.priority != 1 && this.callTimeOut == false) {
        let diff = moment().diff(this.lead.callAt, 'second');
        if (diff < 300) {
          return true;
        }
        return false;
      }
      return false;
    },
    remaining() {
      if (this.lead.callAt) {
        let diff = moment().diff(this.lead.callAt, 'second');
        if (diff < 300) {
          return 1000 * (300 - diff);
        }
        return 0;
      }
      return 0;
    },
    isClaimed() {
      return !!this.lead.userId;
    },
    canBeClaimed() {
      return !this.isClaimed;
    },
    canBeReclaimed() {
      return this.isClaimed && this.lead.userId != this.user.id;
    },
    isReclaimRequested() {
      return this.reclaimSent || this.lead.leadReclaimsCount > 0;
    },
    priority() {
      return this.getLeadPriority(this.lead.priority);
    },
    backgroundClasses() {
      if (this.priority.value) {
        const { cssClass } = this.priority;
        return cssClass;
      } else if (this.lead.userId) {
        return 'bg-no-prior';
      }
      return null;
    },
    emailbackgroundClasses() {
      if (this.priority.value == 1) {
        return 'email-bg bg-email-opened';
      } else if (this.priority.value == 2) {
        return 'email-bg bg-email-sent';
      }
      return 'email-bg';
    },
    icon() {
      return '';
      /*
      let referral = this.lead.referral;
      if (referral.includes('bing.com')) return 'ml-1 fa fa-bold';
      if (referral.includes('bing_ads')) return 'ml-1 fa fa-bold';
      if (referral.includes('ads')) return 'ml-1 fa fa-star';
      if (referral.includes('google')) return 'ml-1 fa fa-star';
      if (referral.includes('aol.com')) return 'ml-1 fa fa-bold';
      if (referral.includes('duckduckgo')) return 'ml-1 fa fa-bold';
      if (referral.includes('yahoo.com')) return 'ml-1 fa fa-bold';
      if (referral.includes('live.com')) return 'ml-1 fa fa-bold';
      return '';
      */
    },
  },
  methods: {
    handleClaim() {
      this.$store.dispatch('leads/claim', { leadId: this.lead.id });
    },
    handleReflow() {
      this.$store.dispatch('leads/reflow', { leadId: this.lead.id });
    },
    handleReclaim() {
      this.reclaimSent = true;
      this.$store.dispatch('leads/reclaim', { leadId: this.lead.id });
      this.clickedReclaim = false;
    },
    handlePriorityChange(newPriority) {
      var leadId = this.lead.id;
      if (newPriority == 3) {
        this.$emit('handleBookedClick', leadId);
      } else if (newPriority == 4) {
        this.$emit('handleBadClick', leadId);
      } else {
        this.$store.dispatch('leads/updatePriority', { leadId: this.lead.id, priority: newPriority });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.callTimeOut = true;
    }, this.remaining);
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-content {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.card-header {
  padding: 0;
  padding-right: 1.5rem;
}
.email-bg {
  padding: 1rem;
  border-right: 1px solid rgba(24, 28, 33, 0.06);
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
  background-color: #fff;
  margin-bottom: -1px;
}
.bg-email-sent {
  background-color: #95e7b8;
}

.bg-email-opened {
  background-color: #78daff;
}
.priorities {
  .btn-prior {
    padding: 12px;
  }
  .nopriority {
    background-color: #dadada;
    border-color: black;
  }
  .hot {
    background-color: #95e7b8;
    border-color: black;
  }
  .future {
    background-color: #f8e287;
    border-color: black;
  }
  .booked {
    background-color: #78daff;
    border-color: black;
  }
  .bad {
    background-color: #f3a69e;
    border-color: black;
  }
}
</style>
