<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Carriers</h1>
      <router-link class="btn btn-primary btn-sm" :to="{ name: 'CarrierNew' }"> <span class="mr-1 fas fa-plus"></span> New carrier </router-link>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="alert alert-secondary">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <span class="fas fa-search"></span>
              </div>
            </div>
            <input class="form-control" :value="searchQuery" @input="startSearch" placeholder="Search" />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <label class="mb-0 mr-2" for="from">From:</label>
            <multiselect id="from" class="mr-4" :value="startState" @input="newValue => updateRouteQuery({ startState: newValue })" :options="states.map(state => state.value)" />
            <label class="mb-0 mr-2" for="to">To:</label>
            <multiselect id="to" class="mr-4" :value="endState" @input="newValue => updateRouteQuery({ endState: newValue })" :options="states.map(state => state.value)" />
          </div>
        </div>

        <LoadingIndicator centered v-if="loading" />
        <table class="table" v-if="!loading && carriers && carriers.length > 0">
          <thead class="table">
            <tr>
              <TableSortableHeader class="px-1" label="Id" field="id" />
              <TableSortableHeader class="px-1" label="Company" field="company" />
              <th scope="col">Contact</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Location</th>
              <TableSortableHeader class="px-1" label="Route" field="route_leads_count" />
              <TableSortableHeader class="px-1" label="Orders" field="leads_count" />
              <th scope="col">Last Order</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="carrier in carriers" :key="carrier.id">
              <td>
                <router-link :to="{ name: 'CarrierDetails', params: { id: carrier.id } }">#{{ carrier.id }}</router-link>
              </td>
              <td>{{ carrier.contact.company }}</td>
              <td>{{ carrier.contact.contact }}</td>
              <td>
                <div>
                  <a :href="`tel:${carrier.contact.phone}`">{{ carrier.contact.phone }}</a>
                </div>
                <div>
                  <a :href="`tel:${carrier.contact.phone2}`">{{ carrier.contact.phone2 }}</a>
                </div>
                <div>
                  <a :href="`tel:${carrier.contact.cellPhone}`">{{ carrier.contact.cellPhone }}</a>
                </div>
              </td>
              <td>
                <a :href="`mailto:${carrier.contact.email}`">{{ carrier.contact.email }}</a>
              </td>
              <td>
                <span v-if="carrier.location">{{ carrier.location.city }}, {{ carrier.location.state }} {{ carrier.location.zip }} </span>
              </td>
              <td>
                {{ carrier.routeLeadsCount }}
              </td>
              <td>
                {{ carrier.leadsCount }}
              </td>
              <td>
                <router-link v-if="carrier.lastOrder" :to="{ name: 'OrderDetails', params: { id: carrier.lastOrder.id } }">
                  {{ carrier.lastOrder.signedAt | formatDate}}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <EmptyState v-if="!loading && !carriers" message="No carriers found matching search criteria" />

        <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import statesMixin from '@/mixins/states';
import pageLinksMixin from '@/mixins/pageLinks';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import EmptyState from '@/components/shared/EmptyState';
import TableSortableHeader from '@/components/shared/TableSortableHeader';
import { debounce } from 'debounce';

const updateSearchUrlParamDebounced = debounce(function (router, search) {
  router.push({ query: { search } });
}, 800);

export default {
  name: 'CarrierList',
  mixins: [pageLinksMixin, statesMixin],
  components: { LoadingIndicator, EmptyState, TableSortableHeader },
  data() {
    return {
      carriers: [],
      loading: false,
      searchQuery: this.$route.query.search,
    };
  },
  computed: {
    startState() {
      const { startState } = this.$route.query;
      return startState;
    },
    endState() {
      const { endState } = this.$route.query;
      return endState;
    },
    hasNextPage() {
      return this.carriers.length === this.perPage;
    },
    fetchParams() {
      const params = {};
      const { startState, endState, sort, search } = this.$route.query;
      if (search) params.search = search;
      if (startState && endState) params.route = `${startState} to ${endState}`;
      if (sort) params.sort = sort;
      params.page = this.currentPage;
      params.perPage = this.perPage;
      return params;
    },
  },
  methods: {
    updateRouteQuery(newQuery) {
      const oldQuery = this.$route.query;
      const query = Object.assign({}, oldQuery, newQuery);
      this.$router.push({ query });
    },
    async fetch() {
      if (this.loading) return;
      this.loading = true;
      const response = await ApiClient.carriers.get({ params: this.fetchParams });
      this.loading = false;
      if (response.status == 200) {
        this.carriers = response.data.data;
      }
    },
    startSearch(event) {
      this.searchQuery = event.target.value;
      if (this.$route.query.search != this.searchQuery) {
        updateSearchUrlParamDebounced(this.$router, this.searchQuery);
      }
    },
  },
  watch: {
    fetchParams() {
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
