import LayoutCentered from '@/layout/LayoutCentered';

import UserList from '@/components/pages/users/UserList';
import User from '@/components/pages/users/User';
import UserNew from '@/components/pages/users/UserNew';
import UserEdit from '@/components/pages/users/UserEdit';
import UserDetails from '@/components/pages/users/UserDetails';
import RolePermissions from '@/components/pages/roles/RolePermissions';

export default [
  {
    path: '/users',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'admin',
    },
    children: [
      {
        path: '',
        name: 'UserList',
        component: UserList,
      },
      { path: 'new', name: 'UserNew', component: UserNew },
      { path: 'role-permissions', name: 'RolePermissions', component: RolePermissions },
      {
        path: 'view/:id',
        component: User,
        children: [
          {
            path: '',
            name: 'UserDetails',
            component: UserDetails,
          },
          {
            path: 'edit',
            name: 'UserEdit',
            component: UserEdit,
          },
          {
            path: 'auth-logs',
            name: 'UserAuthLogs',
            component: () => import('@/components/pages/users/AuthLogs'),
          },
          {
            path: 'lead-views',
            name: 'UserLeadViews',
            component: () => import('@/components/pages/users/LeadViews'),
          },
        ],
      },
    ],
  },
];
