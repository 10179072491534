<template>
  <div>
    <div v-if="carrier.id">
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ carrier.contact ? 'Carrier #' + carrier.id + ' - ' + carrier.contact.company : 'Carrier #' + carrier.id }}</h1>
        <button v-if="!carrier.deletedAt && tokenCan('orders:assign')" class="btn btn-sm btn-danger ml-1" href="#" @click="deleting = true">Delete carrier</button>
        <ConfirmationModal
          v-if="deleting"
          @confirm="removeCarrier"
          @cancel="deleting = false"
          :cancel-label="'No'"
          :confirm-label="'Yes'"
          :label="'Are you sure to delete this carrier?'"
        />
      </div>

      <ul class="nav nav-pills mb-4">
        <TabLink label="View" route-name="CarrierDetails" :id="carrier.id" />
        <TabLink v-if="tokenCan('carriers:update')" label="Edit" route-name="CarrierEdit" :id="carrier.id" />
        <TabLink label="Orders" route-name="CarrierOrders" :id="carrier.id" />
        <TabLink label="Routes" route-name="CarrierRoutes" :id="carrier.id" v-if="false" />
        <TabLink label="Logs" route-name="CarrierLogs" :id="carrier.id" />
        <TabLink label="History" route-name="CarrierHistory" :id="carrier.id" />
        <TabLink label="Attachments" route-name="CarrierAttachments" :id="carrier.id" />
      </ul>

      <router-view :carrier="carrier" @save="updateLocalState" />
    </div>
    <LoadingIndicator v-else centered />
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import TabLink from '@/components/shared/TabLink.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import ConfirmationModal from '@/components/shared/ConfirmationModal';

export default {
  name: 'Carrier',
  components: { LoadingIndicator, TabLink, ConfirmationModal },
  mixins: [permissionsMixin],
  data() {
    return {
      deleting: null,
      carrier: {},
    };
  },
  methods: {
    updateLocalState(carrier) {
      this.carrier = carrier;
    },
    async fetch() {
      const { id } = this.$route.params;
      const apiResponse = await ApiClient.carriers.get({ id });
      this.updateLocalState(apiResponse.data.data);
    },
    async removeCarrier() {
      const { id } = this.carrier;
      const response = await ApiClient.carriers.delete({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'CarriersList' });
      }
    },
  },
  async mounted() {
    await this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
